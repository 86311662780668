* {
  font-family: "Rubik";
}

body {
  padding: 100px;
}

header {
  font-size: 4rem;
  font-weight: bold;
}

@font-face {
  font-family: "Rubik";
  src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXyw023e.woff2);
}
